(function () {
  'use strict';

  angular
    .module('app')
    .component('showStructure', {
      templateUrl: 'app/components/structure/showStructure.html',
      controller: showStructureoperadoraController,
    })

  showStructureoperadoraController.$inject = ['toastr', 'structureService', '$rootScope', 'loteriasService', '$window', '$stateParams','miscelaneos'];

  function showStructureoperadoraController(toastr, $structureService, $rootScope, $loteriasService, $window, $stateParams, $miscelaneos) {
    var vm = this;
    vm.send = false;
    vm.atajos = false;
    vm.save = save;
    vm.cancel = cancel;
    vm.loadingData = true;
    vm.toggleAtajos = toggleAtajos;
    vm.calculateAmount = calculateAmount;
    vm.calculatePercent = calculatePercent;
    vm.calculatePropina = calculatePropina;
    vm.applyPorcentajes = applyPorcentajes;
    vm.updateTipoProducto = updateTipoProducto;
    vm.calculateAmountTriple = calculateAmountTriple;
    vm.updateCurrentStructure = updateCurrentStructure;
    vm.updateConfigLoteriaEstructura = updateConfigLoteriaEstructura;
    vm.type_structures = [{id: 1, nombre: 'operadora'}, {id: 2, nombre: 'comercializador'}, {id: 3, nombre: 'agente'}, {id: 4, nombre: 'agencia'}, {id: 5, nombre: 'jugador'}]

    vm.$onInit = function () {
      $loteriasService.getLoterias(null)
      .then(function(res){
        vm.loterias = res;
        $structureService.showStructure($stateParams.id)
          .then(function (res) {
            vm.structure = res;
            $loteriasService.getLoterias()
              .then(function (res) {
                vm.loteries = res;
                vm.lotteries = _.where(vm.loteries, { tipo: 'triples'})
                vm.sorteosSelected = [];
                _.each(vm.lotteries, function(lotery) {
                  var sorts = _.filter(lotery.sorteos, function(sorteo) {
                    return _.contains(vm.structure.sorteos_rifa, sorteo.id)
                  })
                  if(sorts.length > 0) {
                    vm.sorteosSelected = vm.sorteosSelected.concat(sorts);
                  }
                })


                vm.loadingData = false;
                _.map(vm.structure.configuracion_estructura, function (conf) {
                  var loteria = _.findWhere(vm.loteries, { id: conf.loteria_id })
                  if (!loteria.tipo_loteria) {
                    conf.amount_reference = loteria.configuracion_loteria.propina_premio_mayor;
                  }
                  conf.amount_reference = 0;
                  conf.nombre = loteria.nombre;
                  return conf;
                })
                _.forEach(vm.structure.configuracion_limites, function (conf_limite) {
                  _.forEach(conf_limite.limites, function(limite){
                    var loteria = _.findWhere(vm.loteries, { id: limite.id });
  
                    _.forEach(limite.sorteos, function(sorteo){
                      var limite = _.findWhere(_.findWhere(loteria.sorteos, { id: sorteo.id }).limite_sorteos, { moneda_id: conf_limite.moneda_id})
                      sorteo.porcentajeReference = parseFloat(limite.limite);
                      sorteo.porcentajeReferenceTriple = parseFloat(limite.limite_triple);
                      sorteo.currency = conf_limite.currency;
                      sorteo.moneda_id = conf_limite.moneda_id;
                      calculatePercent(sorteo)
                      calculateAmountTriple(sorteo)
                    })
                  })
                })
                console.log(vm.structure.producto_estructura)
                
                if(vm.structure.producto_estructura.length > 0) {
                  _.forEach(vm.structure.producto_estructura, function(producto){
                    var loteria = _.findWhere(vm.loteries, { id: producto.loteria_id })
                    producto.nombre = loteria.nombre;
                  })
                } else {
                  vm.structure.producto_estructura = [];
                  _.forEach(vm.loteries, function(loteria){
                    vm.structure.producto_estructura.push({
                      loteria_id: loteria.id,
                      nombre: loteria.nombre,
                      activo: false,
                    })
                  })
                }
              })
          })
  
        $miscelaneos.currency.query().$promise
        .then(function(res){
          vm.currencies = res;
        })
      })

    }


    function save() {
      vm.send = true;
      $structureService.updateStructure(vm.structure.id, vm.structure)
        .then(function (res) {
          vm.send = false;
          toastr.info('Estructura modificada con exito');
          $rootScope.$emit('structures', {});

        });


    }

    function updateCurrentStructure(tap, data) {
      vm.send = true;
      switch (tap) {
        case 'firsTap':
          if(vm.structure.use_rifa){
            vm.structure.sorteos_rifa = _.map(vm.sorteosSelected, function(sorteo){
              return sorteo.id;
            })
          }
          $structureService.updateStructure(vm.structure.id, vm.structure)
          .then(function (res) {
            toastr.info(res.message);
            vm.send = false;
          })
          break;
        case 'secondTap':
          var data = _.forEach(vm.structure.configuracion_estructura, function (setting) { delete setting.nombre; delete setting.amount_reference; });
          $structureService.updateStructureConfiguration({ id: vm.structure.id, configuracion_estructura: data })
            .then(function (res) {
              toastr.info(res.message);
              vm.send = false;
            })

          break;
        case 'thirdTap1':

          $structureService.updateUser(data.id, data)
            .then(function (res) {
              toastr.info(res.message);
              vm.send = false;
            })

          break;
        case 'thirdTap2':
          console.log(vm.structure, vm.structure.config_premio)
          $structureService.updateConfigPremio({ config_premio: vm.structure.config_premio, estructura_id: vm.structure.id })
            .then(function (res) {
              toastr.info(res.message);
              vm.send = false;
            })

          break;

        case 'fourTap':

          var limites = [];

          _.forEach(vm.structure.configuracion_limites, function (loteria) {
            _.forEach(loteria.sorteos, function (sorteo) {


              limites.push({
                sorteo_id: sorteo.id,
                porcentaje: sorteo.porcentaje,
                limite: sorteo.limite,
                estructura_id: vm.structure.id,
                limite_triple: sorteo.limite_triple,
                porcentaje_triple: sorteo.porcentaje_triple,
              })
            })
          })


          $structureService.updateLimiteStructure(limites)
            .then(function (res) {
              toastr.info(res.message);
              vm.send = false;
            }, function (res) {
              vm.send = false;
            })


          break;
        default:

      }

    }

    function updateConfigLoteriaEstructura(config) {
      vm.send = true;
      $structureService.updateConfigLoteriaEstructura(vm.structure.id, { config_loteria_estructura: config })
        .then(function (res) {
          toastr.info(res.message);
          vm.send = false;
        }, function (res) {
          vm.send = false;
        })
    }

    function updateTipoProducto(structure) {
      vm.send = true;
      $structureService.updateTipoProducto(structure.id, { productos_estructura: structure.producto_estructura_pretty })
        .then(function (res) {
          vm.send = false;
          $window.swal(res.message, '', 'success');
        })
    }

    function applyPorcentajes(currenLotery) {
      if (vm.globalPercentTriple) {
        _.map(currenLotery.sorteos, function (sorteo) {
          sorteo.porcentaje_triple = angular.copy(vm.globalPercentTriple)
          calculateAmountTriple(sorteo)
          return sorteo;
        })
      }
      if (vm.globalPercentTerminal) {
        _.map(currenLotery.sorteos, function (sorteo) {
          sorteo.porcentaje = angular.copy(vm.globalPercentTerminal)
          calculateAmount(sorteo)
          return sorteo;
        })
      }
      vm.globalPercentTriple = undefined;
      vm.globalPercentTerminal = undefined;
    }

    function toggleAtajos() {
      vm.atajos = !vm.atajos;
    }

    function calculatePercent(data) {
      data.porcentaje = data.limite / data.porcentajeReference * 100;
      setAmountPretty(data)
    }

    function calculateAmount(data) {
      data.limite = data.porcentajeReference * data.porcentaje / 100;
      setAmountPretty(data)
    }

    function setAmountPretty(data) {
      data.pretty_amount = accounting.formatMoney(data.limite, data.currency + ' ', '2', '.', ',');
    }

    function calculatePropina(conf) {
      var monto = conf.comision_premio_mayor * conf.amount_reference / 100;
      conf.monto_participacion = accounting.formatMoney(monto, '', '2', '.', ',');
    }

    function calculateAmountTriple(data) {
      data.limite_triple = data.porcentajeReferenceTriple * data.porcentaje_triple / 100;
      setAmountPrettyTriple(data)
    }

    function setAmountPrettyTriple(data) {
      data.pretty_amount_triple = accounting.formatMoney(data.limite_triple, data.currency + ' ', '2', '.', ',');
    }

    function cancel() {
      vm.dismiss();
    }
  }
})();
